import React from "react";
import headshot from "../img/headshot.jpg";

export const TestimonialsUnveil = ({ style }) => (
  <section className={style === "about" ? "testimonials-dark" : ""}>
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-12">
            <div className="has-text-centered">
              <h3 className="is-size-1 title-underline">
                {style === "about" ? "What People Are Saying" : "Testimonials"}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={style === "about" ? "" : "has-background-grey"}>
      <div className="container">
        <div className="section">
          <div className="columns is-vcentered">
            {/* <div className="column is-3 has-text-centered">
              <img
                src={headshot}
                alt="Headshot"
                className="circle-border"
                style={{ width: "10em", height: "10em" }}
              />
            </div> */}
            <div className="column is-8 is-offset-2">
              <blockquote className="quote">
                <p className="is-size-4">
                  “I ALWAYS use Unveil Production Group for my rental exhibit
                  every year. The job is always done before I even get to the
                  show! Every year we design a new rental exhibit based upon our
                  different offerings! Thank you Unveil Production Group!”
                </p>
                <cite className="is-size-4">
                  -{" "}
                  <strong className={style === "about" ? "has-text-white" : ""}>
                    Rory Jackson, CEO
                  </strong>
                  <br />
                  Just You Photography
                </cite>
              </blockquote>
              {/* <blockquote className="quote">
                <p className="is-size-4">
                  “There are always challenges that come up during a trade show, it’s how they are handled that counts. Unveil Production Group had us up and running by show time with a wonderful rental exhibit after ours was lost in shipping.”
                </p>
                <cite className="is-size-4">
                  - Don Henley, CEO
                  <br />
                  Hunter Enterprises
                </cite>
              </blockquote> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default TestimonialsUnveil;
